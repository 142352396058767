import Sertifikat from "./Sertifikat";
import TambahSertifikat from "./TambahSertifikat";
import EditSertifikat from "./EditSertifikat";

const SertifikatKerjasamaRoutes = [
  {
    path: "/sertifikat_kerjasama/edit/:id",
    component: EditSertifikat,
    exact: false,
  },
  {
    path: "/sertifikat_kerjasama",
    component: Sertifikat,
    exact: true,
  },
  {
    path: "/sertifikat_kerjasama/tambah",
    component: TambahSertifikat,
    exact: false,
  },
];

export default SertifikatKerjasamaRoutes;
