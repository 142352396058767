import {
  Button,
  ButtonGroup,
  Dialog,
} from "@material-ui/core";
import { formatTanggal } from "app/utils/globalFunction";
import React, { Component } from "react";
import { connect } from "react-redux";

class PrintPopup extends Component {
  constructor() {
    super();
  }

  render() {
    let { open, item, handleClose, handlePrint } = this.props;
    return (
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          maxWidth="md"
          fullWidth={true}
        >
          <div className="p-5">
            <h4 className="mb-5">Print Quotation - {item.mitra_name} ({formatTanggal(item.quotation_date)})</h4>
            <div class="flex justify-between pt-12px pb-12px" style={{ maxHeight: '65px' }}>
              <p>{item.about}</p>
              <ButtonGroup>
                <Button onClick={() => handlePrint(item.quotation_code, 0)} variant="contained" color="secondary" className="text-white">
                  Print
                </Button>
                <Button onClick={() => {
                  window.location.href = `/print/log/quotation/${item.quotation_code}`;
                }} variant="contained" color="default">
                  History Print
                </Button>
              </ButtonGroup>
            </div>
            <div className="w-full text-right pt-5">
              <Button onClick={handleClose} variant="contained" color="default">
                Close
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    downloadInvoice: (invoice_code, index, email) =>
      dispatch(downloadInvoice(invoice_code, index, email)),
    updateStatusTermin: (params) => dispatch(updateStatusTermin(params)),
    delLogPrint: (code) => dispatch(delLogPrint(code)),
  };
};

export default connect(null, mapDispatchToProps)(PrintPopup);
