import {
  GET_SERTIFIKAT_KERJASAMA,
  GET_DETAIL_SERTIFIKAT_KERJASAMA,
} from "../actions/SertifikatKerjasamaAction";

const initialState = {
  sertifikat: [],
  detailSertifikat: {},
};

const SertifikatKerjasamaReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SERTIFIKAT_KERJASAMA: {
      return {
        ...state,
        sertifikat: action.payload,
      };
    }
    case GET_DETAIL_SERTIFIKAT_KERJASAMA: {
      return {
        ...state,
        detailSertifikat: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default SertifikatKerjasamaReducer;
