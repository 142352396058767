import { API, setAuthToken } from "app/config/api";

export const DEL_SERTIFIKAT_KERJASAMA = "DEL_SERTIFIKAT_KERJASAMA";
export const GET_SERTIFIKAT_KERJASAMA = "GET_SERTIFIKAT_KERJASAMA";
export const GET_DETAIL_SERTIFIKAT_KERJASAMA = "GET_DETAIL_SERTIFIKAT_KERJASAMA";

export const getAllSertifikatKerjasama = () => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get("/user/sertifikat_kerjasama").catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_SERTIFIKAT_KERJASAMA,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_SERTIFIKAT_KERJASAMA,
        payload: [],
      });
    }
  };
};

export const addSertifikatKerjasama = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.post("/user/sertifikat_kerjasama/create", params).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    return res.data;
  };
};

export const editSertifikatKerjasama = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.post(`/user/sertifikat_kerjasama/edit`, params).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const delSertifikatKerjasama = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.delete(`/user/sertifikat_kerjasama/delete/${params}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: DEL_SERTIFIKAT_KERJASAMA,
        payload: params,
      });
    }
    return res.data;
  };
};

export const downloadSertifikatKerjasama = (sertifikat_code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.post(
      `/user/sertifikat_kerjasama/${sertifikat_code}/cetak`,
      {}
    ).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const getDetailSertifikatKerjasama = (code) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    const res = await API.get(`/user/sertifikat_kerjasama/detail/${code}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_SERTIFIKAT_KERJASAMA,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_SERTIFIKAT_KERJASAMA,
        payload: [],
      });
    }
  };
};
